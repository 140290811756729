import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from "wouter";
import styled from "styled-components";

import Container from "../Container";

import * as CryptoJS from "crypto-js";

declare global {
    interface Window {
        CA_SigilAnim: any;
    }
}

export default function Sigil (props:React.ComponentProps<any>) {

    const [fadeTransition, setFadeTransition] = useState("fadeIn");

    const [location, setLocation] = useLocation();
    const [tokenURI, setTokenURI] = useState("");
    const [tokenId, setTokenId] = useState();
    const [txHash, setTxHash] = useState();
    const [ipfsCid, setIpfsCid] = useState();
    const sigilImg = useRef();


    const reset = () => {
        setTimeout(() => {
            setFadeTransition("fadeOut");
            setTimeout(() => {
                setLocation("/");
            }, 500);
        }, 1000);
    }


    useEffect(() => {
        if (!props.mintTx) {
            setLocation("/");
            return;
        }
      }, []);


    useEffect(() => {
        if (props.mintTx) {
            const mintTx = props.mintTx.mintTx;
            const str = mintTx.events.Mint.returnValues.spell;
            // const tokenId = mintTx.events.Mint.returnValues.tokenId;
            const txHash = mintTx.events.Mint.transactionHash;
            setIpfsCid(props.mintTx.metadata.metadata.ipfs_cid);
            setTxHash(txHash);
            // setTokenId(tokenId);
            setTokenURI(`${process.env.SIGIL_SERVER}/embed/?str=${str}&tx=${txHash}`);
        }
    }, [props.mintTx]);


    const addTokenToWallet = async () => {
        console.log(props.mintTx);
        const result = await props.mintTx.account.provider.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                address: process.env.CONTRACT_ADDRESS,
                symbol: "SIGIL",
                decimals: 0,
                image: 'https://clavesangelicae.com/assets/ca_sigil.svg',
                },
            },
        });
    }


    return(props.mintTx && (
        <>
        <Container title="vii. Sigil" className={fadeTransition} >

            <p style={{textAlign:"center"}}>
                Your token has been minted.
            </p>

            {tokenURI.length > 0 && (
                <div id="sigilEmbed">
                    <div id="sigilSpacer"></div>
                    <iframe scrolling="no" src={tokenURI}></iframe>
                </div>
            )}

            <p style={{textAlign:"center", lineHeight:"2.4em"}}>
                <button className="nav" onClick={addTokenToWallet}>Add Token to Wallet</button>
                <br />
                <a className="nav" href={`${process.env.ETHERSCAN}/tx/${txHash}`} target="_blank">View Tx on Etherscan</a>
                <br />
                <a className="nav" href={`https://ipfs.io/ipfs/${ipfsCid}`} target="_blank">View Sigil on IPFS</a>
                <br />
                <a className="nav" href="https://commerx.clavesangelicae.com/" target="_blank">View Token on Commerx</a>
                <br />
                <a className="nav" href="https://discord.gg/ZvCJJmu4" target="_blank">Join Our Discord</a>
            </p>
        </Container>
        </>
        ) || (<></>)
    );
}
