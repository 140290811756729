import * as React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'wouter';
import styled, { css } from 'styled-components';

import CA_Sigil from "./../../assets/ca_sigil.svg";
import Container from "../Container";
import DotNav from "../DotNav";

const Sigil = styled.div`
    position: relative;
    padding: 0 0;
    img {
        max-width: 450px;
        min-width: 100px;
    }
`;
const DotPos = styled.div`
    position: relative;
    top: -20%;
    left: 50%;
    width: 100px;
    margin-top: 4em;
`;

export default function Index (props:React.ComponentProps<any>) {

    const [location, setLocation] = useLocation();
    const [dotVisible, setDotVisible] = useState(true);
    const [fadeTransition, setFadeTransition] = useState("fadeIn");

    const tokenURI = `${process.env.SIGIL_SERVER}/embed/?str=ofmt58sn50&tx=0xaae44291b1529d99c323efdceabd72b24bec19125a6c1bd0496f7d4b14c861f4`;

    const nextScene = (e:React.MouseEvent) => {
        e.preventDefault();
        setDotVisible(false);
        setFadeTransition("fadeOut");
        setTimeout(() => {
            setLocation("/i");
        }, 500);
    }

    return (
        <>
        <Container title="CLAVES ANGELICÆ" className={fadeTransition}>

            <div id="sigilEmbed">
                <div id="sigilSpacer"></div>
                <iframe scrolling="no" src={tokenURI}></iframe>
            </div>

            <a className="nav fadeInSlow" href="#" onClick={nextScene}>Begin</a>
        </Container>
        </>
    );
}
