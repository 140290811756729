import * as React from 'react';
import { Component, useState, useEffect } from 'react';
import { Link, useLocation } from "wouter";
import styled, { css } from 'styled-components';

import Container from "../Container";

const DotPos = styled.div`
    position:relative;
    left:50%;
    width:100px;
    top:5em;
`;

export default function Initiation () {

    const [location, setLocation] = useLocation();
    const [fadeTransition, setFadeTransition] = useState("fadeIn");

    const nextScene = (e:React.MouseEvent) => {
        e.preventDefault();
        setFadeTransition("fadeOut");
        setTimeout(() => {
            setLocation("/ii")
        }, 500);
    }

    return(
        <>
        <Container title="i. Initiation" className={fadeTransition}>
            <p>
                <em>Claves Angelicæ</em> is a procedural system to inscribe a magical
                Word onto the Ethereum network where it exalted as a sigil in the form of a
                non-fungible token.
            </p>
            <p>
                Magic is the esoteric method of causing change to occur in conformity with one’s Will.
                Magic has many properties, but a primary aspect is coordinating one one’s internal Will
                with one’s external Words.
            </p>
            <p>
                A magical spell is quite literally the spelling of a Word.
            </p>
            <p>
                This seven step process is designed to collect and transmute the participants’
                input data into an encrypted message. The system will guide you through a process of
                constructing a magical Word. Once the spell has been cast, minted, and verified, the
                transaction hash is returned, parsed, and passed into a sigil generating algorithm and
                the participant will receive a tokenized sigil of their spell.
            </p>
            <p>
                Take a moment to connect with your self and contemplate what you desire above all else.
            </p>
            <p>
                Choose wisely. Only 777 sigil tokens will exist.
            </p>

            <br />

            <a className="nav" href="#" onClick={nextScene}>Continue</a>
        </Container>
        </>
    );

}
