import * as React from 'react';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import styled, { css } from 'styled-components';

// WEB3
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Common, { Chain } from '@ethereumjs/common'
import { Transaction as Tx } from '@ethereumjs/tx'

// CONTRACTS
import { AbiItem } from 'web3-utils';
import * as contract from "../artifacts/ClavesAngelicae.json";
const contractABI:AbiItem[] = contract.abi as AbiItem[];
const contractAddress = process.env.CONTRACT_ADDRESS;

// IPFS
import { create as ipfsCreate, Options as ipfsOptions } from "ipfs-http-client";
const ipfsClient = ipfsCreate({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
      authorization: 'Basic ' + Buffer.from(process.env.INFURA_IPFS_PROJECT_ID + ':' + process.env.INFURA_IPFS_PROJECT_SECRET).toString('base64')
    }
} as ipfsOptions);


const ConnectButton = styled.div`
    position:fixed;
    top:0.5em;
    right:1em;
    margin:0;
    & > .addr {
        font-style: italic;
        display:inline-block;
        margin-top: 0.25em;
        font-weight:bold;
        font-size:0.9em;
    }
`;

const DEV_BUTTONS = styled.div`
    position:absolute;
    right:1em;
    top:5em;
    text-align:left;
    & button {
        width:100%;
    }
`;

// walletConnect options
const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            infuraId: process.env.INFURA_ID
        }
    }
};

// truncate address string for UI
const truncate = (str:string) => {
    if (str.length == 0) return;
    const len:number = 4;
    return (
        str.slice(0, len + 2) +
        "..." +
        str.slice(str.length - len,str.length)
    );
}

// Ethereum chain ids
const supportedChainIds = [
    // 1,   // mainnet
    // 3,   // ropsten testnet
    4,      // rinkeby testnet
    // 5,   // gaerli testnet
    // 42,  // kovan testnet
    1337    // localhost (ganacheCli)
]

interface IAccountData {
    web3: Web3,
    provider: any,
    connected: boolean,
    casting: boolean,
    address: string,
    chainId: number,
    networkId: number
}

interface iMetadata {
    name: string,
    tokenId: number,
    image: string,
    spell: string,
    txHash: string
}

const Wallet = (props:any, ref:any) => {

    const accountInit = {
        connected: false,
        casting: false,
        address: "",
        chainId: -1,
        networkId: -1
    } as IAccountData

    const [account, setAccount] = useState(accountInit);

    let provider: any;
    let web3:any;
    let web3Modal = new Web3Modal({
        cacheProvider: true,
        disableInjectedProvider: false,
        providerOptions
    });

    // reconnect on refresh if provider is cached
    useEffect(() => {
        if (web3Modal.cachedProvider) {
            connect();
        }
    },[]);

    useEffect(() => {
        props.onConnected(account);
    }, [account])


    const connect = async () => {

        provider = await web3Modal.connect();
        web3 = new Web3(provider);

        const accounts = await web3.eth.getAccounts();
        const connected = accounts.length > 0;
        const address = accounts.length > 0 ? accounts[0] : "";
        const networkId = await web3.eth.net.getId();
        const chainId = await web3.eth.getChainId();

        await setAccount({
            web3: web3,
            provider: provider,
            connected: true,
            casting: false,
            address: address,
            chainId: chainId,
            networkId: networkId
        });

        provider.on("close", () => {
            console.log("close");
            disconnect();
        });
        provider.on("accountsChanged", async (accounts: string[]) => {
            console.log("accountsChanged", accounts);
            const connected = accounts.length > 0;
            const address = accounts.length > 0 ? accounts[0] : "";
            await setAccount((account) => ({
                ...account,
                connected: connected,
                address: address
            }));
            if (accounts.length == 0) {
                disconnect();
            }
        });
        provider.on("chainChanged", async (chainId: number) => {
            console.log("chainChanged", chainId);
            await setAccount((account) => ({
                ...account,
                chainId: Number(chainId),
            }));
        });
        provider.on("networkChanged", async (networkId: number) => {
            console.log("networkChanged", networkId);
            await setAccount((account) => ({
                ...account,
                networkId: networkId,
            }));
        });
    };


    const changeNetwork = async () => {
        const c = await account.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x4' }]
        });
    }


    const disconnect = async () => {
        if (account.provider && account.provider.close) {
            await account.provider.close();
        }
        await web3Modal.clearCachedProvider();
        account.provider = null;
        await setAccount(accountInit);
    };

    const mintReservedToken = async (account:any, wordEncoded:string, tokenId:any, to_:string) => {

        console.log(account, wordEncoded, to_);
        if (!supportedChainIds.includes(account.chainId)) {
            changeNetwork();
            return;
        }

        const web3 = account.web3;
        const CA = await new web3.eth.Contract(contractABI, contractAddress);

        console.log("Verifying Spell...");
        const spellStr = wordEncoded;
        const verifiedSpell = await CA.methods.verifySpell(spellStr).call();
        console.log(spellStr, verifiedSpell);

        if (verifiedSpell) {

            console.log(`Minting Reserved Token "${spellStr}" from ${account.address} to ${to_}`);
            console.log();

            try {
                const mintTx = await CA.methods.mintReserved("2342342344", tokenId, to_).send({
                    from: account.address
                });
                if (mintTx.status) {
                    const spell = mintTx.events.Mint.returnValues.spell;
                    const tokenId = mintTx.events.Mint.returnValues.tokenId;
                    const txHash = mintTx.events.Mint.transactionHash;
                    const svgCID = await pinSvgToIpfs(spell, tokenId, txHash);
                    if (svgCID) {
                        const metadataJson = await postMetadataJson(svgCID, spell, tokenId, txHash);
                        const onMint = {
                            metadata: metadataJson,
                            mintTx: mintTx,
                            account: account
                        };
                        console.log(onMint);
                        props.onMint(onMint);
                    } else {
                        console.error('IPFS Error');
                        return false;
                    }
                } else {
                    console.error('Minting Error');
                }
            } catch (e) {
                console.error(e);
                return;
            }
        } else {
            console.error("Invalid spell. Reset Rites.", spellStr);
        }

    }


    const mintToken = async (account:any, wordEncoded:string) => {

        if (!supportedChainIds.includes(account.chainId)) {
            changeNetwork();
            return;
        }

        const web3 = account.web3;
        const CA = await new web3.eth.Contract(contractABI, contractAddress);

        console.log("Verifying Spell...");
        const spellStr = wordEncoded;
        const verifiedSpell = await CA.methods.verifySpell(spellStr).call();
        console.log(spellStr, verifiedSpell);

        if (verifiedSpell) {

            await setAccount((account) => ({
                ...account,
                casting: true
            }));

            const price = await CA.methods.PRICE().call();

            console.log(`Minting Spell "${spellStr}" from ${account.address} for ${web3.utils.fromWei(price)} ETH`);

            let mintTx;
            try {
                mintTx = await CA.methods.mint(spellStr).send({
                    from: account.address,
                    value: price
                });
            } catch (e) {
                if (e) {
                    console.log(e);
                    await setAccount((account) => ({
                        ...account,
                        casting: false
                    }));
                }
            }

            if (mintTx && mintTx.status) {
                try {
                    const spell = mintTx.events.Mint.returnValues.spell;
                    const tokenId = mintTx.events.Mint.returnValues.tokenId;
                    const txHash = mintTx.events.Mint.transactionHash;
                    const svgCID = await pinSvgToIpfs(spell, tokenId, txHash);
                    if (svgCID) {
                        const metadataJson = await postMetadataJson(svgCID, spell, tokenId, txHash);
                        const onMint = {
                            metadata: metadataJson,
                            mintTx: mintTx,
                            account: account
                        };
                        // timeout to allow for transiction (sloppy AF)
                        setTimeout(async () => {
                            await setAccount((account) => ({
                                ...account,
                                casting: false,
                            }));
                        }, 1500);
                        console.log(onMint);
                        props.onMint(onMint);
                    } else {
                        console.error('IPFS Error');
                        return false;
                    }
                } catch(e) {
                    console.log(e);
                }
            } else {
                console.error('Minting Error');
            }
        } else {
            alert("Error in casting. Please reset Rites and try again.");
            console.error("Invalid spell. Please Reset Rites.", spellStr);
        }
    }


    // mint token
    useImperativeHandle(ref, () => ({
        castSigil : async (account:any, wordEncoded:string) => {
            mintToken(account, wordEncoded);
        },
        regenereateMetadata : async (spell:string, tokenId:number, txHash:string) => {
            console.log("Regenerating Metadata...");
            const svgCID = await pinSvgToIpfs(spell, tokenId, txHash);
            if (svgCID) {
                const metadataJson = await postMetadataJson(svgCID, spell, tokenId, txHash);
                console.log(metadataJson);
                return metadataJson;
            } else {
                console.error('IPFS Error');
                return false;
            }
        }
    }), []);


    const pinSvgToIpfs = async (spellStr:string, tokenId:number, txHash:string) => {

        // generate SVG
        const sigilUrl = `${process.env.SIGIL_SERVER}/gen/?str=${spellStr}&tx=${txHash}&tokenId=${tokenId}`
        console.log(sigilUrl);
        const res = await fetch(sigilUrl);
        const sigilSvg = await res.text();

        try {
            console.log("Pinning Sigil...");
            const added = await ipfsClient.add(sigilSvg);
            const pinned = await ipfsClient.pin.add(added.path);
            console.log("added:", added, "pinned:", pinned);
            return added.path;
        } catch (e) {
            console.error(e);
        }

        return false;
    }


    const postMetadataJson = async (CID:string, spellStr:string, tokenId:number, txHash:string) => {

        console.log("Posting Metadata...");

        // create metadata
        const metadata = {
            name: `Sigil ${tokenId}`,
            description: `Claves Angelicæ is a procedural system to inscribe a magical Word onto the Ethereum network where it is exalted as a sigil.`,
            spell: spellStr,
            tx: txHash,
            tokenId: tokenId,
            mimeType: "image/svg",
            ipfs_cid: CID,
            image: `ipfs://${CID}`,
            image_data: `ipfs://${CID}`,
            external_url: `${process.env.SIGIL_SERVER}/?id=${tokenId}`,
            animation_url: `${process.env.SIGIL_SERVER}/embed/?id=${tokenId}`
        };

        try {
            const res = await fetch(`${process.env.META_SERVER}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(metadata)
            });
            return await res.json();
        } catch (e) {
            console.error(e);
        }

        return false;
    };

    const [tokenSpell, setTokenSpell] = useState("");
    const [reservedTokenSpell, setReservedTokenSpell] = useState("");
    const [reservedDestAddr, setReservedDestAddr] = useState("");
    const [reservedTokenId, setReservedTokenId] = useState("");
    const devFunctions = () => {
        return(
        <DEV_BUTTONS>

            spell: <input maxLength={10} type="text" placeholder="ofmt58sn50" onChange={(e) => setTokenSpell(e.target.value) }></input>
            <br />
            <button onClick={() =>
                mintToken(account, tokenSpell)
            }>mint token</button>

            <br />
            <br />
            <br />

            spell: <input maxLength={10} type="text" placeholder="ofmt58sn50" onChange={(e) =>
                setReservedTokenSpell( e.target.value )
             }></input>
            <br />
            tokenId: <input style={{width:"40px"}} type="text" placeholder="1" onChange={(e) =>
                setReservedTokenId(e.target.value)
            }></input>
            <br />
            to: <input type="text" placeholder="0x000..." onChange={(e) =>
                setReservedDestAddr(e.target.value)
            }></input>
            <br />
            <button onClick={() =>
                mintReservedToken(account, reservedTokenSpell, reservedTokenId, reservedDestAddr)
            }>mint reserved token</button>

        </DEV_BUTTONS>
        );
    }


    if (account.connected && !supportedChainIds.includes(account.chainId)) {
        return (
            <ConnectButton>
                <button className="nav" onClick={changeNetwork}>Unsupported Network</button>
            </ConnectButton>
        )
    } else if (account.connected) {
        return(<>
            <ConnectButton>
                <button className="nav" onClick={disconnect}>Disconnect</button>
                <br />
                <div className="addr">{(truncate(account.address))}</div>
            </ConnectButton>

            {process.env.DEVELOPMENT && devFunctions()}

            </>
        );
    } else {
        return(
            <ConnectButton>
                <button className="nav" onClick={(connect)}>Connect</button>
            </ConnectButton>
        );
    }
}
export default forwardRef(Wallet);
